<template>
  <div class="m-0 px-3 pt-4 pb-0 pr-2">
    <show-quote :quote-id="viewQuoteModal.id" :show-modal="viewQuoteModal.show" @closeViewModal="closeViewModal"
                @editQuote="showEditModal($event)"/>
    <add-edit-quote :edit-id="addEditQuoteForm.editId" :show-modal="addEditQuoteForm.showModal"
                    :heading-text="addEditQuoteForm.headingText" :button-text="addEditQuoteForm.buttonText"
                    @hideModal="addEditQuoteForm.showModal = $event" @resetEditId="addEditQuoteForm.editId = 0"
                    @getQuotes="getAllQuotes"/>
    <back-button/>
    <div class="d-flex justify-content-between align-items-center">
      <h1 class="m-0">Manage Quotes</h1>
      <div class="d-flex justify-content-end">
        <el-button class="btn-secondary-action title-btn-padding button-font button-hover-effect"
                   @click="showAddModal">
          Add New
        </el-button>
      </div>
    </div>
    <div v-loading="loaders.quotes" class="my-4 cardStyle">
      <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap my-3">
        <div v-if="!selectedRows.length" class="d-flex">
          <base-input class="d-inline-block" label="Search" placeholder="Search..." v-model="request.description"
                      type="search" @change="changeSearchAndGetQuotes" @search="changeSearchAndGetQuotes"/>
          <base-input label="Status" class="ml-3">
            <p @click="clearStatusAndGetQuotes" class="d-inline-block float-right mt--4 mr-2 mb-0 clear-txt">Clear</p>
            <el-select
              class="w-100 status-dropdown"
              label="Status"
              placeholder="Status Select"
              v-model="request.status"
              @change="changeSearchAndGetQuotes"
            >
              <el-option
                v-for="option in dropdowns.status"
                class="select-danger"
                :value="option.value"
                :label="option.label"
                :key="option.label">
              </el-option>
            </el-select>
          </base-input>
        </div>
        <div v-else id="select-option-container" class="mt-4 ml-4">
          <base-button class="mt-0 mb-3 pl-5 pr-5 red-outline-button"
                       @click="promptMultiRemove"
                       :loading="loaders.checkboxButton.Delete"
                       :disabled="loaders.quotes"
          >Delete
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 green-outline-button"
                       @click="handleActivate"
                       :loading="loaders.checkboxButton.Activate"
                       :disabled="loaders.quotes"
          >Activate
          </base-button>
          <base-button class="mt-0 mb-3 pl-5 pr-5 black-outline-button"
                       @click="handleDeactivate"
                       :loading="loaders.checkboxButton.DeActive"
                       :disabled="loaders.quotes"
          >Deactivate
          </base-button>
        </div>
      </div>
      <el-table
        class="table-responsive table-flush checklist-items-table checklist-table"
        header-row-class-name="thead-light"
        width="100%"
        :data="response.quotes"
        @selection-change="sectionListMultipleSelect"
      >
        <el-table-column prop="selected" type="selection" :min-width="10">
        </el-table-column>
        <el-table-column label="DESCRIPTION" :min-width="60">
          <template slot-scope="scope">
            <div class="padding-right-20-percent">{{ scope.row.description }}</div>
          </template>
        </el-table-column>
        <el-table-column label="CREATED ON" :min-width="10">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              {{ scope.row.created | getCreatedOn }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="STATUS" :min-width="10">
          <template slot-scope="scope">
              <span :class="['status',getStatusClass(scope.row.status)]">
                <div class="d-inline-block indicator" :class="getStatusClass(scope.row.status)"></div>
                {{ getStatus(scope.row.status) }}
              </span>
          </template>
        </el-table-column>
        <el-table-column label="ACTIONS" :min-width="10">
          <div slot-scope="{ $index, row }" class="d-flex">
            <el-tooltip
              class="item" effect="dark"
              content="View"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img class="pointer eye-icon mr-2" src="/img/eye.svg" @click="openViewModal(row.id)" alt="">
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Edit"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img class="pointer mr-2" src="/img/icons/buttons/edit.svg" @click="showEditModal(row.id)" alt="">
            </el-tooltip>
            <el-tooltip
              class="item" effect="dark"
              content="Delete"
              placement="bottom-start"
              :visible-arrow="false"
            >
              <img class="pointer" @click="handleDelete($index, row)" src="/img/icons/buttons/delete.svg" alt="Delete"/>
            </el-tooltip>
          </div>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <custom-pagination
            class="pagination-no-border float-right"
            v-model="currentPage"
            :per-page="5"
            :total="totalPages"
            @input="changePage"
          />
        </div>
      </div>
    </div>
    <div class="modals">
      <remove-modal
        :loader="loaders.removeModal"
        :modal-text="removeModalText"
        :removeId="removeId"
        @onRemove="removeBariResource"
        @onRemoveMultiple="removeMultipleQuotes"
      />
    </div>
  </div>
</template>

<script>
import {Table, TableColumn} from "element-ui"
import CustomPagination from "@/views/Components/Pagination/CustomPagination"
import BackButton from "@/components/Router/BackButton"
import moment from "moment"
import AddEditQuote from "@/views/Pages/SuperAdmin/Quotes/AddEditQuote"
import ShowQuote from "@/views/Pages/SuperAdmin/Quotes/ShowQuote";

const RemoveModal = () => import("@/components/Modals/RemoveModal.vue")

export default {
  name: "Quotes",
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    RemoveModal,
    CustomPagination,
    BackButton,
    AddEditQuote,
    ShowQuote
  },
  data() {
    return {
      loaders: {
        quotes: false,
        removeModal: false,
        checkboxButton: {
          Activate: false,
          DeActive: false,
          Delete: false,
        },
      },
      removeModalText: '',
      removeId: '',
      selectedRows: [],
      btnDraggable: 'btnDraggable',
      request: {
        description: '',
        status: ''
      },
      response: {
        quotes: [],
      },
      currentPage: 1,
      totalPages: 1,
      addEditQuoteForm: {
        showModal: false,
        loader: false,
        headingText: 'Add New',
        buttonText: 'Add',
        editId: 0
      },
      viewQuoteModal: {
        id: 0,
        show: false
      },
      dropdowns: {
        status: [
          {value: '', label: 'All Quotes'},
          {value: 1, label: 'Active'},
          {value: 0, label: 'Inactive',},
        ],
      },
    }
  },
  mounted() {
    this.getAllQuotes()
  },
  computed: {
    /**
     * Extract the ids from selected rows from table
     * @returns {*[]}
     */
    extractSelectedIds() {
      return this.selectedRows.map(quote => quote.id)
    },
  },
  filters: {
    /**
     * Reformat created date
     * @param date
     * @returns {string}
     */
    getCreatedOn: function (date) {
      return moment.utc(date).local().format('MMM-DD-YYYY')
    }
  },
  methods: {
    /**
     * Open View Quote Modal
     * @param id
     */
    openViewModal(id) {
      this.viewQuoteModal.id = id
      this.viewQuoteModal.show = true
    },

    /**
     * Close View Quote Modal
     */
    closeViewModal() {
      this.viewQuoteModal.id = 0
      this.viewQuoteModal.show = false
    },

    /**
     * Show Edit Quote Modal
     * @param id
     */
    showEditModal(id) {
      this.addEditQuoteForm.editId = id
      this.addEditQuoteForm.buttonText = 'Update'
      this.addEditQuoteForm.headingText = 'Edit'
      this.addEditQuoteForm.showModal = true
    },

    /**
     * Show Add Quote Modal
     */
    showAddModal() {
      this.addEditQuoteForm.editId = 0
      this.addEditQuoteForm.buttonText = 'Add'
      this.addEditQuoteForm.headingText = 'Add New'
      this.addEditQuoteForm.showModal = true
    },

    /**
     * Change page
     * @param item
     */
    changePage(item) {
      this.currentPage = item
      this.getAllQuotes()
    },

    /**
     * Clear status ang get Quotes
     */
    clearStatusAndGetQuotes() {
      this.currentPage = 1
      this.request.status = ''
      this.getAllQuotes()
    },

    /**
     * Change search and get all recipes
     */
    changeSearchAndGetQuotes() {
      this.currentPage = 1
      this.getAllQuotes()
    },

    /**
     * Get all Quotes
     */
    getAllQuotes() {
      let vm = this
      vm.loaders.quotes = true
      const payload = {
        page: vm.currentPage,
        description: vm.request.description,
        status: vm.request.status,
      }
      vm.$store.dispatch('QuotesModule/_getAllQuotes', payload)
        .then(response => {
          vm.response.quotes = response.data.data.data
          vm.currentPage = response.data.data.current_page
          vm.totalPages = response.data.data.total
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Quotes',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quotes = false
        })
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'Inactive-Status' : 'Active-Status'
    },

    /**
     * Get status as a string
     * @param status
     * @returns {string}
     */
    getStatus(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Handle the active button event
     */
    handleActivate() {
      this.statusUpdateRequest(this.extractSelectedIds, true)
    },

    /**
     * Handle the deactivate button event
     */
    handleDeactivate() {
      this.statusUpdateRequest(this.extractSelectedIds, false)
    },

    /**
     * Handle delete button event
     * @param index
     * @param row
     */
    handleDelete(index, row) {
      this.promptRemove(row)
    },

    /**
     * Prompt remove modal
     * @param row
     */
    promptRemove(row) {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove this Quote.'
      this.removeId = row.id
    },

    /**
     * Prompt the Remove modal for multiple items
     */
    promptMultiRemove() {
      this.$store.commit("showRemoveModal")
      this.removeModalText = 'You want to remove these Quotes.'
      this.removeId = this.extractSelectedIds
    },

    /**
     * Handle event for selecting multiple rows from table
     * @param selectedRows
     */
    sectionListMultipleSelect(selectedRows) {
      this.selectedRows = selectedRows
    },

    /**
     * Handle remove multiple items event
     */
    removeMultipleQuotes() {
      this.deleteRequest(this.removeId)
    },

    /**
     * Handle remove single item event
     * @param id
     */
    removeBariResource(id) {
      this.deleteRequest(id)
    },

    /**
     * Delete Bari Resource(s)
     * @param data
     */
    deleteRequest(data) {
      let vm = this
      vm.loaders.quotes = true
      vm.loaders.checkboxButton.Delete = true
      const payload = {ids: Array.isArray(data) ? data : [data]}
      vm.$store.dispatch('QuotesModule/_deleteQuote', payload)
        .then(response => {
          vm.removeId = []
          vm.getAllQuotes()
          vm.$store.commit("hideRemoveModal")
          vm.$notify.success({
            title: 'Success',
            message: 'Quote Deleted Successfully.'
          })
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Delete Quote',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.checkboxButton.Delete = false
          vm.loaders.quotes = false
        })
    },

    /**
     * Update the status for selected row(s) from table
     * @param ids
     * @param status
     */
    statusUpdateRequest(ids, status) {
      let vm = this
      vm.loaders.quotes = true
      const payload = {
        ids: ids,
        status: status ? 1 : 0,
      }

      vm.$store.dispatch('QuotesModule/_updateStatusQuote', payload)
        .then(response => {
          vm.removeId = []
          vm.$notify.success({
            title: 'Success',
            message: 'Status Updated Successfully.'
          })
          vm.getAllQuotes()
        })
        .catch((error) => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Update Status Quote',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quotes = false
        })
    },
  }
}
</script>

<style scoped>

.padding-right-20-percent {
  padding-right: 20% !important;
}

</style>
