<template>
  <modal v-loading="loaders.quote" @close="cancelForm" :show.sync="isModalOpened">
    <div slot="header">{{ headingText }} Quote</div>
    <validation-observer ref="addEditQuoteForm" v-slot="{handleSubmit}">
      <form class="needs-validation" @submit.prevent="handleSubmit(submitForm)">
        <base-input name="Status" label="Status*" :rules="{required: true}">
          <el-select
            class="w-100"
            label="Status*"
            v-model="request.quote.status">
            <el-option
              v-for="option in dropdowns.status"
              class="select-danger"
              :value="option.value"
              :label="option.label"
              :key="option.label">
            </el-option>
          </el-select>
        </base-input>

        <div class="mb-3">
          <label class="form-control-label">Description*</label>
          <textarea id="textArea"
                    v-model="request.quote.description"
                    class="w-100 text-area-input"
                    placeholder=""
                    required>
          </textarea>
        </div>

        <div class="float-right mt-4">
          <base-button type="secondary" class="ml-auto" @click="cancelForm">
            Cancel
          </base-button>
          <base-button native-type="submit" type="submit" class="btn-primary-action">
            {{ buttonText }}
          </base-button>
        </div>

      </form>
    </validation-observer>
  </modal>
</template>

<script>
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddEditQuote",
  props: {
    showModal: {
      type: Boolean,
      default: false,
      description: 'Hide/Show this modal.'
    },
    headingText: {
      type: String,
      default: 'Add New',
      description: 'Heading text'
    },
    buttonText: {
      type: String,
      default: 'Add',
      description: 'Button text'
    },
    editId: {
      type: Number,
      default: 0,
      description: 'Edit ID for Quote'
    }
  },
  data() {
    return {
      isModalOpened: false,
      request: {
        quote: {...this.$store.getters['QuotesModule/getQuote']}
      },
      loaders: {
        quote: false
      },
      dropdowns: {
        status: [
          {
            value: 1, label: 'Active'
          },
          {
            value: 0, label: 'Inactive',
          },
        ],
      },
    }
  },
  watch: {
    editId(newVal) {
      if (newVal != 0) {
        this.getQuote()
      } else {
        this.resetForm()
      }
    },

    showModal(newVal) {
      this.isModalOpened = newVal
    },
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    /**
     * Get Requested Quote
     */
    getQuote() {
      let vm = this
      vm.loaders.quote = true
      vm.$store.dispatch('QuotesModule/_getQuoteById', {id: vm.editId})
        .then(response => {
          this.request.quote = {...response.data.data}
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Quotes',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quote = false
        })
    },

    /**
     * Handle Cancel form action
     */
    cancelForm() {
      this.resetForm()
      this.$emit('resetEditId')
      this.$emit('hideModal')
    },

    /**
     * Reset add/edit quote form
     */
    resetForm() {
      this.request.quote = {...this.$store.getters['QuotesModule/getQuote']}
      this.$refs.addEditQuoteForm.reset()
    },

    /**
     * Redirect router and emit the relevant function
     * @param {string} formName - Form name
     */
    async submitForm(formName) {
      let vm = this
      const isQuoteFormValid = await this.$refs['addEditQuoteForm'].validate()
      if (isQuoteFormValid) {
        if (vm.request.quote.hasOwnProperty('id') && vm.request.quote.id != 0) {
          vm.updateQuote()
        } else {
          vm.storeQuote()
        }
      } else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        })
      }
    },

    /**
     * Store Quote
     */
    storeQuote() {
      let vm = this
      vm.loaders.quote = true
      vm.$store.dispatch('QuotesModule/_storeQuote', {formData: this.request.quote})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Quote Created Successfully!'
          })
          vm.cancelForm()
          this.$emit('getQuotes')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Quote',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quote = false
        })
    },

    /**
     * Update Quote
     */
    updateQuote() {
      let vm = this
      vm.loaders.quote = true
      vm.$store.dispatch('QuotesModule/_updateQuote', {formData: this.request.quote})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Quote Updated Successfully!'
          })
          vm.cancelForm()
          this.$emit('getQuotes')
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Quote',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quote = false
        })
    },
  }
}
</script>

<style scoped>

.text-area-input {
  width: 100%;
  height: calc(1.5em + 1.25rem + 40px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

</style>
