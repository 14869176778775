<template>
  <modal class="show-quote-modal" v-loading="loaders.quote" @close="closeModal" :show.sync="isModalOpened">
    <div slot="header"><span class="form-control-label font-size-16px">View Info</span></div>
    <hr class="my-3">
    <div slot="default">
      <div class="d-flex justify-content-between m-0 p-0 row align-items-center">
        <div class="d-inline-block">
          <p class="show-status-class px-3 py-1 mb-0"
             :class="getStatusClass(response.quote.status)">
            {{ getStatusAsString(response.quote.status) }}
          </p>
        </div>
        <base-button @click="editQuote" type="secondary" class="py-2 show-edit-button">
          Edit
        </base-button>
      </div>

      <div class="mt-3">
        <h3>DESCRIPTION</h3>
        <div class="overflow-auto py-1 content-section-max-height">
          <span>{{ response.quote.description }}</span>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "ShowQuote",
  props: {
    quoteId: {
      type: Number,
      default: 0,
      description: 'Show ID for Quote'
    },
    showModal: {
      type: Boolean,
      default: false,
      description: 'Hide/Show this modal.'
    },
  },
  data() {
    return {
      response: {
        quote: {...this.$store.getters['QuotesModule/getQuote']}
      },
      isModalOpened: false,
      loaders: {
        quote: false
      }
    }
  },
  watch: {
    quoteId(newVal) {
      if (newVal != 0) {
        this.getQuote()
      }
    },

    showModal(newVal) {
      this.isModalOpened = newVal
    },
  },
  methods: {
    /**
     * Get Quote status as string
     * @param status
     * @returns {string}
     */
    getStatusAsString(status) {
      return status === 0 ? 'Inactive' : 'Active'
    },

    /**
     * Get status class
     * @param status
     * @returns {string}
     */
    getStatusClass(status) {
      return status === 0 ? 'inactive-status-background' : 'active-status-background'
    },

    /**
     * Get Quote by id
     */
    getQuote() {
      let vm = this
      vm.loaders.quote = true
      vm.$store.dispatch('QuotesModule/_getQuoteById', {id: vm.quoteId})
        .then(response => {
          this.response.quote = {...response.data.data}
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Quotes',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.quote = false
        })
    },

    /**
     * Close modal
     */
    closeModal() {
      this.response.quote = {...this.$store.getters['QuotesModule/getQuote']}
      this.$emit('closeViewModal')
    },

    /**
     * Edit Quote
     */
    editQuote() {
      this.closeModal()
      this.$emit('editQuote', this.quoteId)
    },
  },
}
</script>

<style scoped>

.show-quote-modal >>> .modal-header {
  padding-bottom: 0;
}

.show-quote-modal >>> .modal-body {
  padding-top: 0;
}


.show-quote-line-space {

}
</style>
